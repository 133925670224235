import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore';
import "firebase/storage";
import "firebase/auth";

const config = {
        apiKey: "AIzaSyAaNPetcwoNyOTzuwseYT-gEK_Z5lYtssY",
        authDomain: "lounge-production.firebaseapp.com",
        databaseURL: "https://lounge-production.firebaseio.com",
        projectId: "lounge-production",
        storageBucket: "lounge-production.appspot.com",
        messagingSenderId: "610883540725",
        appId: "1:610883540725:web:f80d12e9d49693b31097bc",
        measurementId: "G-442JEKE98T"
        }
        try {
                firebase.initializeApp(config);
        }catch(err){
                if (!/already exists/.test(err.message)) {
                        console.error('Firebase initialization error', err.stack)}
        }


export const app = firebase.app()
export const functions = firebase.functions()
export const db = firebase.firestore()
export const storage = firebase.storage()
export const auth = firebase.auth();

