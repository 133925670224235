import React from 'react' 
import styles from '../styles/MainSection.module.css'

export default function MainSection() {
    
    return (
        <React.Fragment>
            <div className={styles.titleFontContainer} >
                <div className={styles.titleContainerBrowser}>
                    <h1 align="center" className={styles.titleFont}>Audio-first place for teams</h1>
                </div>
                <div className={styles.titleContainermobile}>
                    <h1 align="center" className={styles.titleFont}>Audio-first place</h1>
                    <h1  align="center" className={styles.titleFont}> for teams</h1>
                </div>
            </div>
        </React.Fragment>
        )
}
