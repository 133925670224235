
import React from 'react' 
import styles from '../styles/Header.module.css'


export default function Header() {
    return (
        <React.Fragment >
            <div className={styles.headerContainer}>
           {/* <Toolbar disableGutters >  
                <div className={styles.logoContainer}>
                    <img src="/lounge-logo.svg" alt="logo" className={styles.logo} />
                </div>
                <div  className={styles.buttonContainer}>
                    <Button >
                        <Link 
                            underline="none" 
                            className={styles.aboutButton}
                            href="https://www.notion.so/originofx/About-us-430f58ec841748bd8679cb4656711774">
                                About us
                        </Link>
                    </Button>
                </div>
                
    </Toolbar>*/}
    </div>
        </React.Fragment>
    )
}