import React, {useState, useEffect} from 'react'
import styles from '../styles/SignUp.module.css'
import { InputBase, Grid, Button } from '@material-ui/core'
import {  useHistory } from 'react-router-dom';
import { db }  from '../firebase'
import amplitude from 'amplitude-js';


export default function Singup(){
   
    let history = useHistory()
 
    const [ data, setData ] = useState({
      email: "",
      company: "",
      teamSize:"",
      password: ""
    },[])
    const [error, setError] = useState(false)
  
    useEffect(() => {
      console.log("estoy en el modal")
    })
    const handleValidation = () => {
      let fields = data;
      
      if(!fields["email"] || !fields["company"] || !fields["teamSize"] ){
         setError(true)
      }
      else {
        handleSubmit()
      }
    }
    const handleInputCHange = (event) => {
      setData((prevProps) =>({
        ...prevProps,
        [event.target.name]: event.target.value
      }));
    };
  
    const handleSubmit = (event) => {
      console.log("handle submit")
      const referalUrl = []
        const waitlist = db.collection("waitList").add(data)
        .then(function(ref) {
          const url = `https://lounge.place/?ref=${ref.id}`
          const id = ref.id
          const count = 0
          const isActive = false
          const isJoined = false
          ref.set({url, id, count, isActive, isJoined}, {merge: true})
          referalUrl.push(url)
        })
      console.log("referal",referalUrl)
      history.push('/waitlist', {url: referalUrl})
      //event.preventDefault();
      console.log("data",data)
      amplitude.getInstance().logEvent('Form_start');
    }
    return (
        <React.Fragment>
          <div className={styles.singupContainer}>
            <Grid item  container  justify="center" className={styles.titleFontContainer}>
                <h1 className={styles.titleFont}>Request Access for Mac</h1>
            </Grid>
            <Grid container  align="center" justify="center" spacing={2} className={styles.formContainer}>
                <Grid item md={12} xs={12} lg={12} >
                <InputBase
                    className={styles.textField}
                    id="outlined-email-input"
                    placeholder="Email"
                    variant="outlined"
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={data.email}
                    onChange={handleInputCHange}
                />
                </Grid>
                <Grid item md={12} xs={12} lg={12}>  
                    <InputBase
                    className={styles.textField}
                    id="outlined-company-input"
                    placeholder="Team name"
                    variant="outlined"
                    name="company"
                    value={data.company}
                    onChange={handleInputCHange}
                    />
                </Grid>
                <Grid item md={12} xs={12} lg={12} >
                    <InputBase
                        className={styles.textField}
                        id="outlined-size-input"
                        variant="outlined"
                        name="teamSize"
                        placeholder="How big is your team? "
                        value={data.teamSize}
                        onChange={handleInputCHange}
                    />
                </Grid>
                <Grid item md={12} xs={12} lg={12} className={styles.buttonContainer}>  
                  
                      <Button 
                        variant="contained" 
                        fullWidth 
                        className={styles.actionButton}  
                        size="large" 
                        onClick={handleValidation} 
                        type="submit">
                          Join the waitlist
                      </Button>
                    
                
                </Grid>
                <p className={styles.error}>{error ? "Please complete the required fields!" : ""}</p>
          </Grid>
          </div>
        </React.Fragment>
    )
}