import { BrowserRouter, Switch, Route } from "react-router-dom";
import WaitList from "./waitlist";
import Singup from "./Singup";


const ModalRoutes =() => {
  return (
    <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Singup} />
      <Route path="/waitlist" component={WaitList} />
    </Switch>
  </BrowserRouter>
  )
}
 export default ModalRoutes
