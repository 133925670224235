import React from 'react' 
import styles from '../styles/MainSection.module.css'


export default function LastSection() {
    return (
        <React.Fragment>
           <div className={styles.titleFontContainer} >
               <div className={styles.videoContainer}>
                   <video autoPlay loop muted className={styles.video} playsInline>
                        <source src="./video.webm" type="video/webm"/>
                        <source src="./video.mov" type="video/quicktime"/>   
                    </video>
                    
                    
               </div>
                <div className={styles.titleContainerBrowser}>
                    <h1 align="center" className={styles.titleFont}>Ready for your own</h1>
                    <h1  align="center" className={styles.titleFont}>Lounge?</h1>
                </div>
                <div className={styles.titleContainermobile}>
                    <h1 align="center" className={styles.titleFont}>Ready for your own</h1>
                    <h1  align="center" className={styles.titleFont}>Lounge? </h1>
                </div>
            </div>
        </React.Fragment>
        )
}
