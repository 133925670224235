import React from 'react'
import {Grid, Link} from '@material-ui/core'
import styles from '../styles/Footer.module.css'

export default function Footer() {
    return(
        <React.Fragment>
            <Grid container  className={styles.footerContainer}>
                <Grid item  >
                    <a className={styles.footerButtons}><Link underline="none" style={{color:'black', textTransform: 'none'}} href="https://www.notion.so/originofx/About-us-430f58ec841748bd8679cb4656711774">About</Link></a>
                </Grid>
                <Grid item  >
                    <a className={styles.footerButtons}><Link underline="none" style={{color:'black', textTransform: 'none'}} href="https://twitter.com/Lounge_HQ">Twitter</Link></a>
                </Grid>
                <Grid item>
                    <a className={styles.footerButtons}><Link underline="none" style={{color:'black', textTransform: 'none'}} href="https://www.notion.so/originofx/Privacy-WIP-9d73054dea064e8190045548ce446f4b">Privacy & Terms</Link></a>
                </Grid>
                <Grid item  >
                    <p className={styles.footerButtonsCopyright}>© 2021 Origin of X, Inc.</p>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}