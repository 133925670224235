import React, { useState, useEffect} from 'react' 
import styles from '../styles/MainSection.module.css'
import {Typography} from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function PicSection() {
    return (
        <React.Fragment>
            <div className={styles.picSectionContainer}>
             
                <div className={styles.imgContainer}>
                  
                        <LazyLoadImage 
                            effect="opacity" 
                            src="/1_3x.webp" 
                            className={styles.img}
                            />
               
                        <LazyLoadImage 
                            effect="opacity"  
                            src="/mobile1.webp" 
                            className={styles.imgMobile}/>
                    
                </div>
              
                <div className={styles.textWraper}>
                    <div className={styles.titleFontPicContainer}>
                        <Typography aling="left" className={styles.titleFontPic}>Fluid conversation on Voice. </Typography>
                    </div>
                    <div className={styles.pContainer}>
                        <Typography className={styles.p}>Combine the best of open floor plan and uninterrupted deep work. Glance and talk to the right people, at the right time. </Typography>
                    </div>    
                </div>
            </div>
                
       
        </React.Fragment>
)} 

PicSection.getInitialProps = () => {

}