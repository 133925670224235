import React, { useEffect, useState } from 'react'
import MainPage from './MainPage'
import {db, functions}  from './firebase'

export default function App() {
  const [link, setLink] = useState("[]")
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ref = params.get('ref');
  console.log("ref Count", ref)
 

  useEffect( async () => {
    const docs = []
    const waitListRef = await db.collection("waitList")
        .where("id", "==", ref)
        .get()
        waitListRef.forEach(doc => {
          docs.push(doc.data())
          
          console.log("waitList: ", toString(waitListRef))
          const callable = functions.httpsCallable('waitListReferalCount');
          callable({ref}).then(console.log) 
        })
        console.log("docs: ",docs)
          
   
    setLink(docs)
  },[])
  
  return (
    <div>
      <MainPage />  
    </div>
    
  )
}
