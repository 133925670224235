import React, { useState, useEffect } from 'react'
import styles from './styles/Home.module.css'
import Header from './components/Header'
import MainSection from './components/MainSection'
import ThirdSection from './components/ThirdSection';
import Footer from './components/Footer'
import PicSection from './components/picSection'
import ModalRoutes from './components/modalRoutes'
import LastSection from './components/LastSection';
import { db }  from './firebase'
import {Typography, Container, Button, Modal} from '@material-ui/core'
import {  useHistory } from 'react-router-dom';
import amplitude from 'amplitude-js';

amplitude.getInstance().init("f8ed7663f3423b6c049ab9307f2c3ddc", function() { console.log('init complete'); });


export default function MainPage() {
  
  let history = useHistory()
  const [modal, setModal] = useState(false)
  const [url, setUrl] = useState()
    const [inLine, setInLine] = useState(parseInt(0))

    const fetchData = async () => {
        const waitListRef = db.collection('waitListCount').doc('count');
        const doc = await waitListRef.get();
        if (!doc.exists) {
            console.log('No such document!');
        } else {
            const data =  doc.data()
            const wlCount = parseInt(data.count)
            console.log("count", wlCount) 
        setInLine(wlCount)
        }
        }
    
    useEffect( async () => {
        var referrer = document.referrer;
         console.log("referrer url",referrer);
        fetchData()
    }, [])

  const handleModal = () => {
    amplitude.getInstance().logEvent('home_page_landing', {includeReferrer: true} );
    setModal(true);
  };
  const handleclose= () => {
    setModal(false)
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  const body = (
      <div className={styles.modal} >
        <ModalRoutes/>
      </div>
      )
  return (
    <React.Fragment >
      <Container maxWidth="lg" className={styles.container}>
        <Header />
        <main className={styles.mainContainer}>
          <MainSection/>
          <div className={styles.buttonContainer}>
              <Button variant="contained" className={styles.actionButton} onClick={handleModal}>Request Access for Mac</Button>
              <Modal style={{backgroundColor:'rgba(10,10,10,.60',  outline: 'none !important'}} open={modal} onClose={handleclose}>
                <div style={{outline:0}}> {body}</div>
              </Modal>  
          </div>
          <div className={styles.inLineContainer}>
              <Typography  className={styles.inLineCount}>{numberWithCommas(inLine)}  {' '} </Typography> 
              <Typography  className={styles.peopleInLine}>teams in line</Typography>
          </div>
          <PicSection/>
          <ThirdSection/>
          <LastSection/>
          <div className={styles.buttonContainer}>
              <Button variant="contained" className={styles.actionButton}  onClick={handleModal}>Request Access for Mac</Button>
          
          <div className={styles.inLineContainer}>
              <Typography  className={styles.inLineCount}>{inLine}  {' '} </Typography> 
              <Typography  className={styles.peopleInLine}>teams in line</Typography>
          </div></div>
        </main>
        <Footer/>
      </Container>
     
    </React.Fragment>
  )
}
