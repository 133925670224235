import React from 'react'
import styles from '../styles/ThirdSection.module.css'
import {Typography} from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



export default function ThirdSection() {
    return (
        <React.Fragment>
         <div className={styles.thirdSectionContainer}>
                <div className={styles.imgContainer}>
          
                        <LazyLoadImage 
                            effect="opacity" 
                            src="/2_3x.webp" 
                            className={styles.img}
                            />
                   
                        <LazyLoadImage 
                            effect="opacity"  
                            src="/mobile2.webp" 
                            className={styles.imgMobile} />
           
                </div>
                <div className={styles.textWraper}>
                    <div className={styles.titleFontPicContainer}>
                        <Typography aling="right" className={styles.titleFontPic}>Topic-based notes on Text.</Typography>
                    </div>
                    <div className={styles.PContainerBrowser}>
                        <Typography className={styles.p1}>
                            Messages are organized into rooms on Lounge.
                        </Typography>
                        <Typography className={styles.p2}>
                          Catalogue your action items by topics and stay aligned with your team at scale. 
                        </Typography>
                    </div>    
                    <div className={styles.PContainerMobile}>
                        <Typography className={styles.p}>
                            Messages are organized into rooms on Lounge.  Catalogue your action items by topics and stay aligned with your team at scale. 
                        </Typography>
                    </div> 
                </div>
                </div>

        </React.Fragment>
    )
}