import React, {useState, useEffect} from 'react'
import styles from '../styles/WaitList.module.css'
import {Typography, Grid, Button } from '@material-ui/core'
import { useLocation } from "react-router-dom";
import useClipboard from "react-use-clipboard";
import {  useHistory } from 'react-router-dom';
import amplitude from 'amplitude-js';


export default function WaitList(props) {
    
    let history = useHistory()
    const location = useLocation();
    const [link, setLink] = useState("")
    const [isCopied, setCopied] = useClipboard(link)
    console.log("iscopied",isCopied)
    if (isCopied === true) {
        
        console.log(console.log("iscopied",isCopied))
    }
  
    useEffect(() => {
        
        const url = location.state.url
        setLink(url)
        console.log("new url", url )
        amplitude.getInstance().logEvent('Form_completed');
       
        
    }, [location]);

    return (
        <React.Fragment>
            <div className={styles.waitlistContainer}>
            <Grid container  justify="center" className={styles.titleFontContainer}>
                <h1 className={styles.titleFont}>Thank you!</h1>
            </Grid>
                <p className={styles.subTitle}>We have added you to the sign up que. </p>
            <Grid container  align="center" justify="center" >
                <img src="/logo-lounge.png" alt="room" className={styles.img}/>
            <Grid item container  className={styles.buttonContainer}>
                <h1 className={styles.logoSubTitle}> Interested in faster access?</h1>
                <p className={styles.subTitle1}>Get earlier access by helping us spread the words. The more people that join, the sooner you’ll get access.</p>
                <p className={styles.subTitle2}></p>
                <Button variant="contained" fullWidth className={styles.actionButton}  size="large" onClick={setCopied} type="submit">{isCopied ? "invite URL copied" : "Share this link"}</Button>
            </Grid>
          </Grid>
          </div>
        </React.Fragment>
    )
}